<template lang="pug">
  div.scrollable
    .field(v-for="ef in editorFormat")
      label.label(v-text="ef.name")
      .control
        div.editor_group(v-if="ef.type == 'Array'")
          div(v-for="(child, childIndex) in ef.children")
            .columns
              .column(v-for="ch in child")
                .field
                  label.label(v-text="`${childIndex + 1}.${ch.name}`")
                  .control
                    input.input(v-bind:type="ch.type" v-model="ch.value")
              .column
                button.button.is-large.is-danger(type="button" v-on:click="removeItem(ef.children, childIndex)")
                  i.fas.fa-times
          .buttons.is-centered
            button.button.add_item_button(type="button" v-on:click="ef.children.push(cloneLastItem(ef.children))") +
        span(v-else)
          input.input(v-bind:type="ef.type" v-model="ef.value")
</template>

<script>
export default {
  props: {
    // エディタフォーマット。詳細は #8
    editorFormat: {
      type: Array,
      required: true
    }
  },

  methods: {
    cloneLastItem(arr) {
      const lastItem = arr[arr.length - 1]
      return lastItem.map(item => {
        const clonedItem = {...item}
        clonedItem.value = ''

        return clonedItem
      })
    },

    // 最後の1つの場合は削除成功させない。追加できなくなってしまうため
    removeItem(arr, index) {
      if (arr.length <= 1) {
        return false
      }

      arr.splice(index, 1)
      return true
    },

    exportData() {
      const data = {}
      this.editorFormat.forEach(rootItem => {
        if (rootItem.type == 'Array') {
          data[rootItem.key] = []
          rootItem.children.forEach((items, idx) => {
            data[rootItem.key][idx] = {}
            items.forEach(item => {
              data[rootItem.key][idx][item.key] = item.type == 'number' ? Number(item.value) : item.value
            })
          })
        } else {
          data[rootItem.key] = rootItem.type == 'number' ? Number(rootItem.value) : rootItem.value
        }
      })

      return data
    },

    importData(data) {
      this.editorFormat.forEach((rootItem) => {
        if (rootItem.type == 'Array') {
          data[rootItem.key].forEach((dataItems, dataIndex) => {
            if (!rootItem.children[dataIndex]) {
              rootItem.children.push(this.cloneLastItem(rootItem.children))
            }

            for (let [k, v] of Object.entries(dataItems)) {
              const item = rootItem.children[dataIndex].find(item => item.key == k)
              if (item) {
                item.value = v
              }
            }
          })

          // フォームの要素が大きくなりすぎないように揃える
          while(data[rootItem.key].length < rootItem.children.length) {
            rootItem.children.pop()
          }
        } else {
          // vueの検知機構が死なないようにプロパティ追加する
          // rootItem.value = data[rootItem.key]
          this.$set(rootItem, 'value', data[rootItem.key])
        }
      })
    },

    log(item) {
      console.log(item)
    },
  },

  mounted() {
    window.app = this
  }
}
</script>

<style lang="scss" scoped>
.add_item_button {
  margin-top: 18px;
}
.editor_group {
  padding: 16px;
  border: 1px #dfdfdf solid;
}
.scrollable {
  height: 400px;
  overflow-y: scroll;
  padding: 10px;
  border: 2px solid #15caaa;
}
</style>