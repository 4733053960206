<!--
  マークポイントやスキップポイント機能。
  主に発声練習向け。
-->
<template lang="pug">
  .accordion(:class="{'is-active': isActive}")
    animation-frame-manager(ref="animationFrameManager" v-on:update="progress()")
    .accordion-header(:class="{toggle: togglable}")
      p(v-text="standardSettings.name")
    .accordion-body
      .accordion-content
        .buttons.is-centered
          button.button.is-info.is-large(v-show="!playing" v-on:click="play()")
            i.fas.fa-play
          button.button.is-info.is-large(v-show="playing" v-on:click="pause()")
            i.fas.fa-pause
        .buttons(v-if="detailSettings.skipPoints")
          input(class="slider is-fullwidth is-large is-info" step="1" min="-1" :max="detailSettings.skipPoints.length -1" v-model="skipPointRange" type="range" v-on:change="onSkipPointRangeChanged()")
        .buttons.is-centered
          button.button(type="button" v-for="(button, buttonKey) in detailSettings.buttons" v-on:click="play(button.time)" v-bind:class="{ 'is-primary': button.time < currentTime }" v-bind:disabled="disableFromSkipPoint(button)")
            span
              span.has-text-weight-bold(v-text="`${buttonKey + 1} `")
              span.is-size-7(v-text="button.text")

</template>

<script>
import { Howl, Howler } from 'howler'

export default {
  props: {
    accordionId: String,
    standardSettings: {
      type: Object,
      default: () => { return { name: 'name' } }
    }, // ActiveRecord内由来のもの
    detailSettings: {
      type: Object
    }, // マークポイント等のデータ
    isActive: {
      type: Boolean,
      default: false
    },
    togglable: {
      type: Boolean,
      default: true
    }
  },

  data: () => {
    return {
      loaded: false,
      duration: 0,
      howl: null,
      playing: false,
      currentTime: 0,
      currentTimeText: '',
      skipPointRange: -1,
      seeking: false,
    }
  },

  computed: {
  },

  methods: {
    emitOperation (method, args = []) {
      this.$emit('operation', { id: this.standardSettings.on_lesson_id, method: method, args: args })
    },

    play (currentTime = null, emit = true) {
      if (emit) {
        this.emitOperation('play', [currentTime])
      }
      this.pause(false)

      if (currentTime != null) {
        this.howl.seek(currentTime)
      }

      this.howl.play()
      this.$refs.animationFrameManager.start()
    },

    pause (emit = true) {
      if (emit) {
        this.emitOperation('pause')
      }

      this.howl.pause()
      this.playing = false
      this.$refs.animationFrameManager.stop()
    },

    importDetailSettings (v) {
      this.detailSettings = v
    },

    disableFromSkipPoint (button) {
      const skipPoint = this.detailSettings.skipPoints?.[this.skipPointRange]
      if (!skipPoint) {
        return false
      }
      return skipPoint.from < button.time && button.time < skipPoint.to
    },

    onSkipPointRangeChanged (skipPointRange = this.skipPointRange, emit = true) {
      if (emit) {
        this.emitOperation('onSkipPointRangeChanged', [skipPointRange])
      }

      this.skipPointRange = skipPointRange
    },

    // animationFrameManager update
    progress (vm = this) {
      const currentTime = this.howl.seek()

      // seek中は数字以外が来るので、数字のときだけ処理
      if (!isNaN(currentTime)) {
        vm.currentTime = currentTime
        vm.currentTimeText = `${parseInt(currentTime / 60)}:${parseInt(currentTime) % 60}`
      }
      this.playing = vm.howl.playing()

      // skipPointの処理
      if (vm.playing && vm.detailSettings.skipPoints && vm.skipPointRange != -1 && !vm.seeking) {
        const skipPoint = vm.detailSettings.skipPoints[vm.skipPointRange]

        // toから数秒前にずらしておかないと、iosで永久ループが出る。
        if (vm.currentTime > skipPoint.from && vm.currentTime < skipPoint.to - 1.00) {
          vm.seeking = true
          vm.howl.once('seek', () => vm.seeking = false )
          vm.howl.seek(skipPoint.to)
        }
      }
    }
  },

  mounted() {
    this.howl = new Howl({
      src: [this.standardSettings.main_audio],
      volume: 1.0,
      html5: true,
      onload: (event) => {
        this.loaded = true
        this.duration = this.howl.duration()
        this.$emit('loaded', this)
      }
    })
  }

}
</script>

<style lang="scss" scoped>

</style>