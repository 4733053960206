// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import "core-js/stable"
import "regenerator-runtime/runtime"

require("@rails/ujs").start()
require("@rails/activestorage").start()
require("channels")

import Vue from 'vue'
import TurbolinksAdapter from 'vue-turbolinks'
Vue.use(TurbolinksAdapter)
import AudioManager from '../components/AudioManager.vue'
Vue.component('audio-manager', AudioManager)
import LessonAudioEditor from '../components/LessonAudioEditor.vue'
Vue.component('lesson-audio-editor', LessonAudioEditor)
import LessonAudioTraining from '../components/LessonAudioTraining.vue'
Vue.component('lesson-audio-training', LessonAudioTraining)
import LessonAudioMusic from '../components/LessonAudioMusic.vue'
Vue.component('lesson-audio-music', LessonAudioMusic)
import AnimationFrameManager from '../components/AnimationFrameManager.vue'
Vue.component('animation-frame-manager', AnimationFrameManager)

window.Vue = Vue

import bulmaAccordion from 'bulma-extensions/bulma-accordion/dist/js/bulma-accordion.js'
window.bulmaAccordion = bulmaAccordion

import bulmaCalendar from 'bulma-calendar/dist/js/bulma-calendar.js'
window.bulmaCalendar = bulmaCalendar

import {Howl, Howler} from 'howler'
window.Howler = Howler

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)
