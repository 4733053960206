<!--
  分割数、シーク機能
-->
<template lang="pug">
  .accordion(:class="{'is-active': isActive}")
    animation-frame-manager(ref="animationFrameManager" v-on:update="progress()")
    .accordion-header(:class="{toggle: togglable}")
      p(v-text="standardSettings.name")
    .accordion-body
      .accordion-content
        .buttons.is-centered
          button.button.is-info.is-large(v-show="!playing" v-on:click="play()")
            i.fas.fa-play
          button.button.is-info.is-large(v-show="playing" v-on:click="pause()")
            i.fas.fa-pause
        .buttons
          input(class="slider is-fullwidth is-large is-primary" step="1" min="0" :max="duration" v-model="currentTime" type="range" v-on:touchstart="onCurrentTimeStart()" v-on:mousedown="onCurrentTimeStart()" v-on:change="onCurrentTimeChanged()" v-on:input="updateCurrentTimeText()")
        .buttons.is-centered
          button.button(v-for="n in divisionNumber" v-on:click="play((duration / divisionNumber * (n - 1)))" v-bind:class="{ 'is-primary': (duration / divisionNumber * (n - 1)) < currentTime }")
            span(v-text="n")
  
</template>

<script>
import {Howl} from "howler";

export default {
  props: {
    accordionId: String,
    standardSettings: {
      type: Object,
      default: () => { return { name: 'name' } }
    }, // ActiveRecord内由来のもの
    detailSettings: {
      type: Object
    }, // マークポイント等のデータ
    isActive: {
      type: Boolean,
      default: false
    },
    togglable: {
      type: Boolean,
      default: true
    }
  },

  data: () => {
    return {
      loaded: false,
      duration: 0,
      howl: null,
      playing: false,
      currentTime: 0,
      currentTimeText: '',
      seeking: false,

      played: false, // タイムバー動作前に再生中だったか。タイムバー操作時の状態保存
    }
  },

  computed: {
    divisionNumber () {
      return parseInt(this.detailSettings['divisionNumber'] || 30)
    }
  },

  methods: {
    emitOperation (method, args = []) {
      this.$emit('operation', { id: this.standardSettings.on_lesson_id, method: method, args: args })
    },

    play (currentTime = null, emit = true) {
      if (emit) {
        this.emitOperation('play', [currentTime])
      }
      this.pause(false)

      if (currentTime != null) {
        this.howl.seek(currentTime)
      }

      this.howl.play()
      this.$refs.animationFrameManager.start()
    },

    pause (emit = true) {
      if (emit) {
        this.emitOperation('pause')
      }

      this.howl.pause()
      this.playing = false
      this.$refs.animationFrameManager.stop()
    },

    importDetailSettings (v) {
      this.detailSettings = v
    },

    onCurrentTimeStart() {
      this.played = false

      if (this.playing) {
        this.howl.pause()
        this.$refs.animationFrameManager.stop()
        this.played = true
      }
      console.log('onCurrentTimeStart')
    },

    onCurrentTimeChanged(currentTime = null, emit = false) {
      if (currentTime != null) {
        this.currentTime = currentTime
      }

      if (emit) {
        this.emitOperation('onCurrentTimeChanged', [this.currentTime])
      }

      this.howl.seek(this.currentTime)

      if (this.played) {
        this.howl.pause()
        this.howl.play()
        this.$refs.animationFrameManager.start()
      }
      console.log('onCurrentTimeChanged')
    },

    updateCurrentTimeText() {
      this.currentTimeText = `${parseInt(this.currentTime / 60)}:${parseInt(this.currentTime) % 60}`
    },

    // animationFrameManager update
    progress (vm = this) {
      const currentTime = this.howl.seek()

      // seek中は数字以外が来るので、数字のときだけ処理
      if (!isNaN(currentTime)) {
        vm.currentTime = currentTime
        vm.currentTimeText = `${parseInt(currentTime / 60)}:${parseInt(currentTime) % 60}`
      }
      this.playing = vm.howl.playing()
    }

  },

  mounted() {
    this.howl = new Howl({
      src: [this.standardSettings.main_audio],
      volume: 1.0,
      html5: true,
      onload: (event) => {
        this.loaded = true
        this.duration = this.howl.duration()
        this.$emit('loaded', this)
      }
    })

    if (!this.detailSettings['divisionNumber']) {
      this.detailSettings['divisionNumber'] = 30
    }
  }
}
</script>

<style scoped>

</style>