<!--
  音のコントロールを行う。
-->
<script>
  import {Howl, Howler} from 'howler'

  export default {
    data() {
      return {
        howl: null,
        loaded: false,
        duration: null,
      }

    },
    props: {
      // html5で読み込むか。
      // メリット: メモリ使用量が圧倒的少ない。
      // デメリット: iOSで音量調整できない。再生操作系のレスポンスが若干悪い。(メトロノームはだめ)
      html5: {
        type: Boolean,
        default: true
      },
      volume: {
        type: Number,
        default: 1.0
      },
      src: {
        type: String,
        required: true
      }
    },

    mounted() {
      console.log(`will load file "${this.src}"`)

      this.howl = new Howl({
        src: [this.src],
        volume: 1.0,
        html5: this.html5,
        onload: (event) => {
          this.loaded = true
          this.duration = this.howl.duration()
          this.$emit('onload', event)
        }}
      )
    }
  }
</script>
