<!--
  requestAnimationFrame をマネジメントする。

  methods:
    start 処理を開始する
    stop 処理を終了する

  event:
    update
-->
<template lang="pug">

</template>

<script>
export default {
  data: () => {
    return {
      progressId: null,
    }
  },

  methods: {
    start () {
      if (this.progressId != null) {
        return
      }

      const vm = this
      const progress = () => {
        vm.progressId = window.requestAnimationFrame(progress)
        vm.onUpdate()
      }
      progress()
    },

    stop () {
      if (this.progressId == null) {
        return
      }
      window.cancelAnimationFrame(this.progressId)
      this.onUpdate(this)
      this.progressId = null
    },

    onUpdate (vm = this) {
      vm.$emit('update')
    }
  }
}
</script>

<style scoped>

</style>